body {
    font-family: monospace;
}

/*
  .page {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}
*/

h1,
h2,
h3,
h4,
h5,
h6 {
    padding-top: 1em;
    padding-bottom: 1em;
}

.breathe {
    margin: 1em;
}

nav {
}

nav li {
    margin-right: 2em;
    display: inline;
}
