// thanks to github @xe
// https://github.com/Xe/gruvbox-css/blob/master/gruvbox.css

main {
    font-family: monospace, monospace;
}

@media only screen and (max-device-width: 736px) {
    main {
        padding: 0rem;
    }
}

::selection {
    background: #d3869b;
}

body {
    background: #282828;
    color: #ebdbb2;
}

pre {
    background-color: #3c3836;
    padding: 1em;
    border: 0;
}

a,
a:active,
a:visited {
    color: #b16286;
    background-color: #1d2021;
}

h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 0.1rem;
}

blockquote {
    border-left: 1px solid #bdae93;
    margin: 0.5em 10px;
    padding: 0.5em 10px;
}

footer {
    align: center;
}

@media (prefers-color-scheme: light) {
    body {
        background: #fbf1c7;
        color: #3c3836;
    }

    pre {
        background-color: #ebdbb2;
        padding: 1em;
        border: 0;
    }

    a,
    a:active,
    a:visited {
        color: #b16286;
        background-color: #f9f5d7;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-bottom: 0.1rem;
    }

    blockquote {
        border-left: 1px solid #655c54;
        margin: 0.5em 10px;
        padding: 0.5em 10px;
    }
}
