body {
    margin-left: 2em;
    margin-right: 2em;
}

.TopDo {
    text-align: center;
}

/*
          padding: 20px;
            width: 200px;
              height: 150px;
          }
*/
. .topdolist {
}

.todoItem {
    border-radius: 25px;
    border: 2px solid #585858;
    font-size: 14pt;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    margin-left: -2em;
    margin-top: 1em;
    margin-bottom: 1em;
    /* max-width: 80%; */
    min-width: 900pt;
    white-space: nowrap;
    line-height: 3em;
    height: 2.5em;
    list-style-type: none;
}

.itemTOPDO > .todoText {
    font-size: 36pt;
    color: #c23474;
    text-overflow: ellipsis;
}

.itemCOMPLETED > .todoText {
    text-decoration: line-through;
    color: #585858;
}

button {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: monospace;
    /*input has OS specific font-family*/
    color: #b16286;
    text-decoration: underline;
    cursor: pointer;
}
